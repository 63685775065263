<!-- [팝업] 설문지 검색 -->
<template>
  <b-modal
    v-model="showCommentSurveySelect"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="투표형 댓글 투표지 검색"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        md="10"
        class="d-flex align-items-center justify-content-center"
      >
        <b-form-input
          v-model="query.filterStr"
          class="el-def"
          placeholder="검색어를 입력하세요."
          @keyup.enter="refetchData()"
        />
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="refetchData()"
        >
          조회
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-table
        ref="refCommentSurveyTable"
        primary-key="id"
        :items="fetchCommentSurveys"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedCommentSurvey"
        @row-dblclicked="onRowDblClickedCommentSurvey"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(modifyDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="currPage"
          :total-rows="totalRecords"
          limit="10"
          per-page="10"
          align="center"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
  },

  props: {
    showCommentSurveySelect: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
      // fetchCodes()
    })

    const toast = useToast()

    // Query Data
    const query = ref({
      filterStr: null,
    })
    // Query Data End.

    const commentSurveyData = ref(null)

    // Main Table Config
    const refCommentSurveyTable = ref(null)
    const sortBy = ref('modifyDate')
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { key: 'title', label: '투표지 제목', sortable: true },
      { key: 'createDate', label: '등록일', sortable: true },
      { key: 'modifyDate', label: '최종 수정일', sortable: true },
    ]
    const refetchData = () => {
      refCommentSurveyTable.value.refresh()
    }
    watch([currPage], () => {
      refetchData()
    })
    const onRowSelectedCommentSurvey = items => {
      const item = items[0]
      if (item) {
        commentSurveyData.value = item
      }
    }
    const onRowDblClickedCommentSurvey = item => {
      if (item) {
        commentSurveyData.value = item
      }
      submit()
    }
    // Main Table Config End.

    const fetchCommentSurveys = (ctx, callback) => {
      axios.post('/fa/comment-survey/quick-search', {
        search: {
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
        pagination: {
          number: currPage.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '투표형 댓글 투표지 데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      if (commentSurveyData.value === null) {
        toast({
          component: ToastificationContent,
          props: {
            title: '투표지를 선택하세요',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      emit('close', commentSurveyData.value)
    }

    return {
      query,
      close,
      submit,
      refCommentSurveyTable,
      sortBy,
      currPage,
      isSortDesc,
      totalRecords,
      tableColumns,
      refetchData,
      onRowSelectedCommentSurvey,
      onRowDblClickedCommentSurvey,
      fetchCommentSurveys,
    }
  },
}
</script>
